// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    dashboard: path(ROOTS_DASHBOARD, "/dashboard"),
    opsDashboard: path(ROOTS_DASHBOARD, "/opsDashboard"),
    feedback: path(ROOTS_DASHBOARD, "/feedback"),
    collectContainers: path(ROOTS_DASHBOARD, "/collectContainers"),
    zoloBatch: path(ROOTS_DASHBOARD, "/zoloBatch"),
    markAvailable: path(ROOTS_DASHBOARD, "/markAvailable"),
    packagingReport: path(ROOTS_DASHBOARD, "/packaging-report"),
    liveCityStockListing: path(ROOTS_DASHBOARD, "/liveCityStockListing"),
    liveClientStockListing: path(ROOTS_DASHBOARD, "/liveClientStockListing"),
    liveFacilityStockListing: path(
      ROOTS_DASHBOARD,
      "/liveFacilityStockListing"
    ),
    inventorySent: path(ROOTS_DASHBOARD, "/inventorySent"),
    inventorySentEdit: path(ROOTS_DASHBOARD, "/inventorySentEdit"),

    graphSent: path(ROOTS_DASHBOARD, "/graphSent"),



    inventorySentListing: path(ROOTS_DASHBOARD, "/inventorySentListing"),
    inventoryTransitSentListing: path(
      ROOTS_DASHBOARD,
      "/inventoryTransitSentListing"
    ),
    addContainer: path(
      ROOTS_DASHBOARD,
      "/addContainer"
    ),
    deleteContainer: path(
      ROOTS_DASHBOARD,
      "/deleteContainer"
    ),
    containersListing: path(
      ROOTS_DASHBOARD,
      "/containersListing"
    ),
    inventoryReceived: path(ROOTS_DASHBOARD, "/inventoryReceived"),
    inventoryReceivedEdit: path(ROOTS_DASHBOARD, "/inventoryReceivedEdit"),

    inventoryReceivedListing: path(
      ROOTS_DASHBOARD,
      "/inventoryReceivedListing"
    ),
    inventoryTransitReceivedListing: path(
      ROOTS_DASHBOARD,
      "/inventoryTransitReceivedListing"
    ),
    inventoryEOD: path(ROOTS_DASHBOARD, "/inventoryEOD"),
    inventoryEODListing: path(ROOTS_DASHBOARD, "/inventoryEODListing"),
    inventoryClientEOD: path(ROOTS_DASHBOARD, "/inventoryClientEOD"),
    inventoryClientEODListing: path(
      ROOTS_DASHBOARD,
      "/inventoryClientEODListing"
    ),
    inventoryEOW: path(ROOTS_DASHBOARD, "/inventoryEOW"),
    inventoryEOWListing: path(ROOTS_DASHBOARD, "/inventoryEOWListing"),
    // inventoryClient: path(ROOTS_DASHBOARD, "/inventoryClient"),
    // inventoryAddB2B: path(ROOTS_DASHBOARD, "/inventoryAddB2B"),
    inventoryPurchase: path(ROOTS_DASHBOARD, "/inventoryPurchase"),
    inventoryPurchaseListing: path(
      ROOTS_DASHBOARD,
      "/inventoryPurchaseListing"
    ),

    inventoryPurchaseMovement: path(
      ROOTS_DASHBOARD,
      "/inventoryPurchaseMovement"
    ),

    cumulativeBillingListing : path(ROOTS_DASHBOARD, "/CumulativeBillingListing"),
    invoiceBillingListing : path(ROOTS_DASHBOARD, "/InvoiceBillingListing"),
    invoiceBillingListing2 : path(ROOTS_DASHBOARD, "/InvoiceBillingListing2"),

    viewInvoice : path(ROOTS_DASHBOARD, "/ViewInvoice"),
    viewChallanPdf : path(ROOTS_DASHBOARD, "/ViewChallanPdf"),




    // client SKU

    clientSKUListing : path(ROOTS_DASHBOARD, "/ClientSKUListing"),
    addClientSKUMap : path(ROOTS_DASHBOARD, "/AddClientSKUMap"),

    addClientPlan : path(ROOTS_DASHBOARD, "/AddClientPlan"),
    clientPlanListing : path(ROOTS_DASHBOARD, "/ClientPlanListing"),
    everydayClientInventoryValues : path(ROOTS_DASHBOARD, "/EverydayClientInventoryValues"),




    //OPS Details
    addOpsShiftReport : path(ROOTS_DASHBOARD, "/AddOpsShiftReport"),
    opsShiftReportListing: path(ROOTS_DASHBOARD, "/OpsShiftReportListing"),
    addOpsQCReport : path(ROOTS_DASHBOARD, "/QCList"),
    opsQCReportListing: path(ROOTS_DASHBOARD, "/QCReportListing"),
    // addOpsQCReport : path(ROOTS_DASHBOARD, "/AddOpsQCReport"),
    // opsQCReportListing: path(ROOTS_DASHBOARD, "/OpsQCReportListing"),
    addFacilityManPowerCount : path(ROOTS_DASHBOARD, "/AddFacilityManPowerCount"),
    facilityManPowerCountListing: path(ROOTS_DASHBOARD, "/FacilityManPowerCountListing"),
    addWaterEfficiency : path(ROOTS_DASHBOARD, "/AddWaterEfficiency"),
    waterEfficiencyListing: path(ROOTS_DASHBOARD, "/WaterEfficiencyListing"),
    addClientEscalation : path(ROOTS_DASHBOARD, "/AddClientEscalation"),
    clientEscalationListing: path(ROOTS_DASHBOARD, "/ClientEscalationListing"),
    addEscalationType : path(ROOTS_DASHBOARD, "/AddEscalationType"),
    escalationTypeListing: path(ROOTS_DASHBOARD, "/EscalationTypeListing"),

    //Weekly Review
    addProjectCost : path(ROOTS_DASHBOARD, "/AddProjectCost"),
    addProjectCostTable : path(ROOTS_DASHBOARD, "/AddProjectCostTable"),

    projectCostListing: path(ROOTS_DASHBOARD, "/ProjectCostListing"),
    addReviewCost : path(ROOTS_DASHBOARD, "/AddReviewCost"),
    reviewCostTypeListing: path(ROOTS_DASHBOARD, "/ReviewCostTypeListing"),
    addReviewCostCategory : path(ROOTS_DASHBOARD, "/AddReviewCostCategory"),
    reviewCostCategoryListing: path(ROOTS_DASHBOARD, "/ReviewCostCategoryListing"),
    addRevenue : path(ROOTS_DASHBOARD, "/AddRevenue"),
    revenueListing: path(ROOTS_DASHBOARD, "/RevenueListing"),
    addFacilityCost : path(ROOTS_DASHBOARD, "/AddFacilityCost"),
    facilitycostListing: path(ROOTS_DASHBOARD, "/FacilityCostListing"),
    AddLogistic : path(ROOTS_DASHBOARD, "/AddLogistic"),
    logisticListing: path(ROOTS_DASHBOARD, "/LogisticListing"),

       //Vehicle
       addVehicle : path(ROOTS_DASHBOARD, "/AddVehicle"),
       vehicleListing: path(ROOTS_DASHBOARD, "/VehicleListing"),

     //User 
     addUser : path(ROOTS_DASHBOARD, "/AddUser"),
     userListing: path(ROOTS_DASHBOARD, "/UserListing"),

     //Client
     addClient : path(ROOTS_DASHBOARD, "/AddClient"),
     clientListing: path(ROOTS_DASHBOARD, "/ClientListing"),

      //masterPlan
      masterPlanListing : path(ROOTS_DASHBOARD, "/masterPlanListing"),
    // addMasterPlan: path(ROOTS_DASHBOARD, "/AddMasterPlan"),

    createTransitPlan: path(ROOTS_DASHBOARD, "/createTransitPlan"),
    createForm: path(ROOTS_DASHBOARD, "/create-form"),
    planMaster: path(ROOTS_DASHBOARD, "/plan-master"),
    driverReport: path(ROOTS_DASHBOARD, "/driver-report"),
    createPlan: path(ROOTS_DASHBOARD, "/plan-master/create"),
    notScheduled: path(ROOTS_DASHBOARD, "/notScheduled"),
    b2bBatch: path(ROOTS_DASHBOARD, "/b2bBatch"),
    b2bBatchListing: path(ROOTS_DASHBOARD, "/b2b-batch-listing"),
    alerts: path(ROOTS_DASHBOARD, "/alerts"),
    dispatch: path(ROOTS_DASHBOARD, "/dispatch"),
    eodReport: path(ROOTS_DASHBOARD, "/eodReport"),
    ledger: path(ROOTS_DASHBOARD, "/ledger"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
    inventory: path(ROOTS_DASHBOARD, "/inventory"),
    invoice: path(ROOTS_DASHBOARD, "/invoice"),
    authtokens: path(ROOTS_DASHBOARD, "/auth-tokens"),
    orderListing: path(ROOTS_DASHBOARD, "/order-listing"),
    weeklyReport: path(ROOTS_DASHBOARD, "/weekly-report"),
    orderVerificationReport: path(ROOTS_DASHBOARD, "/order-verification"),
    overview: path(ROOTS_DASHBOARD, "/overview"),
    metrics: path(ROOTS_DASHBOARD, "/metrics"),
    history: path(ROOTS_DASHBOARD, "/history"),
    insights: path(ROOTS_DASHBOARD, "/insights"),
    mamaketo: path(ROOTS_DASHBOARD, "/mamaketo"),
    shop: path(ROOTS_DASHBOARD, "/shop"),
    mamaketoDispatch: path(ROOTS_DASHBOARD, "/mamaketo-dispatch"),
    mamaketoCollection: path(ROOTS_DASHBOARD, "/mamaketo-collection"),
    mamaketoHistory: path(ROOTS_DASHBOARD, "/mamaketo-history"),
    issues: path(ROOTS_DASHBOARD, "/issues"),
    view: (id) => path(ROOTS_DASHBOARD, `/issues/${id}`),
    viewImage: (id) => path(ROOTS_DASHBOARD, `/transit/${id}`),
    demoView: path(ROOTS_DASHBOARD, "/issues/0"),
    collectionReport: path(ROOTS_DASHBOARD, "/collectionAgent-report"),
    petPoojaReport: path(ROOTS_DASHBOARD, "/petpooja-report"),
    optedForBag: path(ROOTS_DASHBOARD, "/optedForBag"),
    dispatchPlan: path(ROOTS_DASHBOARD, "/dispatchPlan"),
    transitPlan: path(ROOTS_DASHBOARD, "/transit-plan"),
    qcListing: path(ROOTS_DASHBOARD, "/qc-listing"),
    qcForm: path(ROOTS_DASHBOARD, "/qc-form"),
    restaurantStatusHistory: path(ROOTS_DASHBOARD, "/restaurantStatusHistory"),
    collectionSheet: path(ROOTS_DASHBOARD, "/collectionSheet"),
    continerCountList: path(ROOTS_DASHBOARD, "/continerCountList"),
    batchListing: path(ROOTS_DASHBOARD, "/batchListing"),
    rfidBatch: path(ROOTS_DASHBOARD, "/rfidBatch"),
    createBatch: (id) => path(ROOTS_DASHBOARD, `/batchListing/${id}`),
    OptinOrderList: path(ROOTS_DASHBOARD, "/optin-orders"),
    manageRestaurants: path(ROOTS_DASHBOARD, "/manage-restaurants"),
    managePlatforms: path(ROOTS_DASHBOARD, "/manage-platforms"),
    manageUserTypes: path(ROOTS_DASHBOARD, "/manage-user-types"),
    manageUsers: path(ROOTS_DASHBOARD, "/manage-users"),
  },
  client: {
    new: path(ROOTS_DASHBOARD, '/client/new'),
    list: path(ROOTS_DASHBOARD, '/client/list'),
    edit: path(ROOTS_DASHBOARD, '/client/edit'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"
    ),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    new: path(ROOTS_DASHBOARD, "/blog/new"),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
