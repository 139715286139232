import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const procurMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Containers",
        children: [
          {
            title: "Add Container",
            path: PATH_DASHBOARD.general.addContainer,
          },
          {
            title: "SKU List",
            path: PATH_DASHBOARD.general.containersListing,
          },
          {
            title: "Delete Container",
            path: PATH_DASHBOARD.general.deleteContainer,
          },
        ],
      },
      {
        title: "Purchase Inventory",
        children: [
          {
            title: "ADD Purchase Inventory",
            path: PATH_DASHBOARD.general.inventoryPurchase,
          },
          {
            title: "Purchase Inventory Listing",
            path: PATH_DASHBOARD.general.inventoryPurchaseListing,
          },
          {
            title: "Purchase Movement Inventory",
            path: PATH_DASHBOARD.general.inventoryPurchaseMovement,
          },
        ],
      },

    ],
  },
];

export { procurMenu  };
