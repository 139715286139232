import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const shiftSuperMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Transit Plan",
        icon: ICONS.booking,
        children: [
          {
            title: "Transit Plan Listing",
            path: PATH_DASHBOARD.general.transitPlan,
            icon: ICONS.grid,
          },
         
        ],
      },
     
      {
        title: "Driver Report",
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.general.driverReport,

      },
      
      {
        title: "Ops Details",

        children: [

          {
            title: "Ops Shift Report",
            children: [
              {
                title: "Add Ops Shift Status",
                path: PATH_DASHBOARD.general.addOpsShiftReport,
              },
              {
                title: "Ops Shift Report listing",
                path: PATH_DASHBOARD.general.opsShiftReportListing,
              },
            ],
          },
          {
            title: "Ops QC Report",
            children: [
              {
                title: "Add Ops QC Status",
                path: PATH_DASHBOARD.general.addOpsQCReport,
              },
              {
                title: "Ops QC Report listing",
                path: PATH_DASHBOARD.general.opsQCReportListing,
              },
            ],
          },
          {
            title: "Faclity Man Power",
            children: [
              {
                title: "Add Facility Man Power Count",
                path: PATH_DASHBOARD.general.addFacilityManPowerCount,
              },
              {
                title: "Facility Man Power listing",
                path: PATH_DASHBOARD.general.facilityManPowerCountListing,
              },
            ],
          },
          {
            title: "Plates Washed",
            children: [
              {
                title: "Add Plates Washed",
                path: PATH_DASHBOARD.general.addWaterEfficiency,
              },
              {
                title: "Plates Washed listing",
                path: PATH_DASHBOARD.general.waterEfficiencyListing,
              },
            ],
          },
          {
            title: "Client Escalation",
            children: [
              {
                title: "Add Client Escalation",
                path: PATH_DASHBOARD.general.addClientEscalation,
              },
              {
                title: "Client Escalation listing",
                path: PATH_DASHBOARD.general.clientEscalationListing,
              },
            ],
          },
          {
            title: "Escalation Type",
            children: [
              {
                title: "Add Escalation Type",
                path: PATH_DASHBOARD.general.addEscalationType,
              },
              {
                title: "Escalation Type listing",
                path: PATH_DASHBOARD.general.escalationTypeListing,
              },
            ],
          },
        ],
      },
    

      {
        title: "Inventory Count",
        children: [
          {
            title: "Live Stock Count",
            children: [
              {
                title: "Client Stock",
                path: PATH_DASHBOARD.general.liveClientStockListing,
              },
              {
                title: "Facility Stock",
                path: PATH_DASHBOARD.general.liveFacilityStockListing,
              },
              {
                title: "City Stock",
                path: PATH_DASHBOARD.general.liveCityStockListing,
              },
            ],
          },
          {
            title: "Sent Inventory",
            children: [
              {
                title: "Sent Transit Plan",
                path: PATH_DASHBOARD.general.inventoryTransitSentListing,
              },
              {
                title: "Sent Inventory listing",
                path: PATH_DASHBOARD.general.inventorySentListing,
              },
            ],
          },
          {
            title: "Received Inventory",
            children: [
              {
                title: "Received Transit Plan",
                path: PATH_DASHBOARD.general.inventoryTransitReceivedListing,
              },
              {
                title: "Received Inventory listing",
                path: PATH_DASHBOARD.general.inventoryReceivedListing,
              },
            ],
          },
          {
            title: "Facility EOD Inventory",
            children: [
              {
                title: "Add EOD Inventory",
                path: PATH_DASHBOARD.general.inventoryEOD,
              },
              {
                title: "EOD Inventory listing",
                path: PATH_DASHBOARD.general.inventoryEODListing,
              },
            ],
          },
          {
            title: "Client EOD Inventory",
            children: [
              {
                title: "Add EOD Inventory",
                path: PATH_DASHBOARD.general.inventoryClientEOD,
              },
              {
                title: "EOD Inventory listing",
                path: PATH_DASHBOARD.general.inventoryClientEODListing,
              },
            ],
          },
          {
            title: "EOW Inventory",
            children: [
              {
                title: "Add EOW Inventory",
                path: PATH_DASHBOARD.general.inventoryEOW,
              },
              {
                title: "EOW Inventory listing",
                path: PATH_DASHBOARD.general.inventoryEOWListing,
              },
            ],
          },
   
          {
            title: "Purchase Inventory",
            children: [
              {
                title: "ADD Purchase Inventory",
                path: PATH_DASHBOARD.general.inventoryPurchase,
              },
              {
                title: "Purchase Inventory Listing",
                path: PATH_DASHBOARD.general.inventoryPurchaseListing,
              },
              {
                title: "Purchase Movement Inventory",
                path: PATH_DASHBOARD.general.inventoryPurchaseMovement,
              },
            ],
          },
        ],
      },
    ],
  },
];

export { shiftSuperMenu   };
